import React, { useRef } from "react"

const AdsA8 = props => {
  const container = useRef(null)
  const { children } = props

  return (
    <ins
      style={{
        ...{
          display: `block`,
          margin: `0 auto`,
        },
        ...props.style,
      }}
      ref={container}
    >
      {children}
    </ins>
  )
}

export default AdsA8
