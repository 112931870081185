import qs from "qs"
import React from "react"
import url from "url"
import EmbedHatenaCard from "./embed-hatena-card"

const ifreme = videoId => {
  const stylePlayer = {
    maxWidth: "100%",
    aspectRatio: "16 / 9",
    height: "auto",
    display: "block",
    margin: "0 auto var(--spacing-5)",
  }
  return (
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
      referrerPolicy="no-referrer"
      loading="lazy"
      style={stylePlayer}
    />
  )
}

const EmbedYouTube = props => {
  const targetURL = url.parse(props.href)

  // TODO: Quoraでは直接iframeの埋め込みをしていないで画像表示にしている。
  // クリックした場合のみ、iframeに差し替えて動画再生できるような工夫をしている。素晴らしい。※同様のものを採用したいが後回し。
  // https://img.youtube.com/vi/${videId}/0.jpg

  // TODO: 冗長なのでもう少し整理したい
  if (
    targetURL.hostname === "www.youtube.com" &&
    targetURL.pathname.split("/")[1] === "watch"
  ) {
    const q = qs.parse(targetURL.query)
    const videoId = q.v
    return ifreme(videoId)
  } else if (targetURL.hostname === "youtu.be") {
    const videoId = targetURL.pathname.split("/")[1]
    return ifreme(videoId)
  } else {
    // NOTE: 暫定対応 YouTubeへのリンクかつ、動画ではないページの場合の処理
    return <EmbedHatenaCard {...props} />
  }
}

export default EmbedYouTube
