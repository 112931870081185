import React, { useEffect, useRef } from "react"

const AdsNend = props => {
  const container = useRef(null)

  const adsCustomLink = () => {
    const adsLinks = container.current.querySelectorAll("a")
    adsLinks.forEach(element => {
      element.target = `_blank`
    })
  }

  // useLayoutEffect(() => {
  useEffect(() => {
    const baseDom = container.current

    const s1 = document.createElement(`script`)
    s1.defer = true
    s1.innerHTML = `var nend_params = {media:${props.media},site:${props.site},spot:${props.spot},type:${props.type},oriented:${props.oriented}};`
    s1.async = true
    baseDom.appendChild(s1)

    const s2 = document.createElement(`script`)
    s2.type = `text/javascript`
    s2.async = true
    s2.src = `https://js1.nend.net/js/nendAdLoader.js`
    baseDom.appendChild(s2)

    window.addEventListener(`load`, adsCustomLink)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.spot])

  return (
    <ins
      style={{
        ...{
          display: `block`,
          margin: `0 auto`,
        },
        ...props.style,
      }}
      ref={container}
    />
  )
}

export default AdsNend
