import React, { useState } from "react"

const EmbedHatenaCard = props => {
  const [active, setActive] = useState(false)

  const styleCard = {
    display: "block",
    width: "100%",
    height: "150px",
    margin: "var(--spacing-5) 0",
    border: "none",
    overflow: "hidden",
    backgroundColor: active ? "transparent" : "#eee",
  }

  const loadEnd = () => {
    console.log("hatena loaded.", active, setActive)
    setActive(!active)
  }

  return (
    <iframe
      src={`https://hatenablog-parts.com/embed?url=${encodeURIComponent(
        props?.href
      )}`}
      frameBorder="0"
      style={styleCard}
      title={props?.title}
      loading="lazy"
      referrerPolicy="no-referrer"
      target="_blank"
      onLoad={loadEnd}
    />
  )
}

export default EmbedHatenaCard
