import { graphql, Link } from "gatsby"
import React from "react"

import AuthorBio from "../components/author-bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { EmbedEmbedly } from "../components/embed-embedly"
import Mdx from "../components/Mdx"
import Share from "../components/share"

import AdsA8 from "../components/ads-a8"
import AdsNend from "../components/ads-nend"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || ``
  const { previous, next } = data
  const path = location?.pathname

  return (
    <Layout location={location} title={siteTitle}>
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header
          className="blog-post__header"
          style={{
            padding: `0 var(--spacing-4)`,
          }}
        >
          <small className="blog-post__header-published">
            {post.frontmatter.date}
          </small>
          <h1 itemProp="headline" className="blog-post__header-headline">
            {post.frontmatter.title}
          </h1>
        </header>
        <AdsA8 {...{ style: { maxWidth: `300px` } }}>
          <a
            href="https://px.a8.net/svt/ejp?a8mat=3NGU10+93GJCQ+4D4Y+609HT"
            rel="nofollow"
          >
            <img
              border="0"
              width="300"
              height="250"
              alt=""
              src="https://www26.a8.net/svt/bgt?aid=220815108550&wid=006&eno=01&mid=s00000020365001009000&mc=1"
            />
          </a>
          <img
            border="0"
            width="1"
            height="1"
            src="https://www16.a8.net/0.gif?a8mat=3NGU10+93GJCQ+4D4Y+609HT"
            alt=""
          />
        </AdsA8>
        <div
          style={{
            padding: `0 var(--spacing-4)`,
          }}
        >
          <Mdx itemProp="articleBody">{post.body}</Mdx>
        </div>
        {post.frontmatter.ads?.map((ad, index) => {
          return (
            <EmbedEmbedly {...{ href: ad.trim() }} key={index}></EmbedEmbedly>
          )
        })}
        <Share
          title={post.frontmatter.title}
          url={`${data.site.siteMetadata.siteUrl}${location.pathname}`}
          description={post.frontmatter.description || post.excerpt}
        />
        <footer>
          <AuthorBio
            author={{
              name: post.frontmatter.author_name,
              summary: post.frontmatter.author_summary,
              url: post.frontmatter.author_url,
              picture: post.frontmatter.author_picture,
            }}
          ></AuthorBio>
        </footer>
        <AdsNend
          {...{
            media: 70767,
            site: 358657,
            spot: 1063759,
            type: 1,
            oriented: 1,
            style: {
              marginTop: `var(--spacing-2)`,
              marginBottom: `var(--spacing-2)`,
            },
            path,
          }}
        />
      </article>
      <nav
        style={{
          padding: `0 var(--spacing-4)`,
        }}
      >
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(truncate: true, pruneLength: 70)
      body
      frontmatter {
        title
        date(formatString: "YYYY年MM月DD日")
        description
        canonical
        image
        author_name
        author_picture
        author_summary
        author_url
        ads
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

export default BlogPostTemplate

export const Head = ({ data }) => {
  const post = data.mdx
  const title = post?.frontmatter?.title
  const answerText = post?.excerpt || post?.frontmatter?.description
  const description = `💬 に対しての名回答 🐨🎤 ${answerText}`
  const segmenter = new Intl.Segmenter(`ja`, { granularity: `grapheme` })
  const ogTextMax = 70
  const ogText =
    [...segmenter.segment(title)].length <= ogTextMax
      ? title
      : `${[...segmenter.segment(title)]
          .map(obj => obj?.segment)
          .slice(0, ogTextMax - 1)
          .join(``)}…`
  // console.log("title length: ", [...segmenter.segment(title)].length)
  return (
    <Seo title={title} description={description}>
      <meta
        id="og:image"
        property="og:image"
        content={`https://placehold.jp/70/3d4070/ffffff/1200x630.png?text=${encodeURIComponent(
          ogText
        )}&css=%7B%22padding%22%3A%22%200%201.5em%22%2C%22text-align%22%3A%22%20left%22%7D`}
      />
      <meta id="og:image:alt" property="og:image:alt" content={title} />
      <meta id="og:type" property="og:type" content="article" />
    </Seo>
  )
}
