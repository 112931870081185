import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import React from "react"
import url from "url"
import EmbedHatenaCard from "./embed-hatena-card"
import EmbedYouTube from "./embed-youtube"

// @see: https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/
export default function Mdx({ children, frontmatter }) {
  return (
    <MDXProvider
      components={{
        p: props => {
          // NOTE: リンクのみの行の場合、はてなブログカードに変換
          // TODO: Twitterのリンクの場合は埋め込みたい気持ち有り
          if (props?.children?.props?.mdxType === "a") {
            const targetURL = url.parse(props.children.props.href)

            if (
              targetURL.hostname === "www.youtube.com" ||
              targetURL.hostname === "youtu.be"
            ) {
              return <EmbedYouTube {...props.children.props} />
            }

            return <EmbedHatenaCard {...props.children.props} />
          }
          return <p {...props} />
        },
      }}
    >
      <MDXRenderer itemProp="articleBody">{children}</MDXRenderer>
    </MDXProvider>
  )
}
