import React from "react"

const styleBio = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#eee",
  padding: "var(--spacing-6) var(--spacing-4)",
}

const styleAvatar = {
  marginRight: "var(--spacing-4)",
  marginBottom: "var(--spacing-0)",
  minWidth: "50px",
  height: "50px",
  borderRadius: "100%",
}

const styleSummary = {
  marginBottom: "var(--spacing-0)",
}

const styleName = {
  fontWeight: "bold",
}

const AuthorBio = ({ author }) => {
  return (
    <div style={styleBio}>
      {author?.picture && (
        <img style={styleAvatar} src={author.picture} alt="hoge"></img>
      )}
      {author?.name && (
        <p style={styleSummary}>
          {author?.summary || null}
          <br></br>
          <strong style={styleName}>{author.name}</strong>
        </p>
      )}
    </div>
  )
}

export default AuthorBio
